<template>
  <v-card>
    <v-card-title>
      <v-btn @click="openModal" color="primary" class="text-capitalize"> Nowy użytkownik </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="shrink"
        label="Wyszukaj"
        single-line
        hide-details
        @blur="fetchUsers"
        @keyup.enter="fetchUsers"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:[`item.emailConfirmed`]="{item}">
        {{ showStatus(item.emailConfirmed) }}
      </template>
      <template v-slot:[`item.changeStatus`]="{item}">
        <v-tooltip right>
          <template v-slot:activator="{on}">
            <v-icon v-on="on" size="15" @click="changeStatus(item)">{{
              item.emailConfirmed ? 'mdi-cancel' : 'mdi-check-circle-outline'
            }}</v-icon>
          </template>
          <span>
            {{ item.emailConfirmed ? 'Zablokuj konto' : 'Odblokuj konto' }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <UsersModal @userAdded="fetchUsers" ref="modal" />
    <Confirm ref="confirmModal" />
  </v-card>
</template>

<script>
import UsersModal from '@/components/Users/UsersModal'
import Confirm from '@/components/UI/Confirm'
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin'
import RepositoryFactory from '@/repositories/RepositoryFactory'

const UsersRepository = RepositoryFactory.get('users')

export default {
  name: 'Users',
  mixins: [paginationMixin],
  components: {
    UsersModal,
    Confirm,
  },
  data() {
    return {
      loading: false,
      totalCount: 0,
      search: '',
      headers: [
        {text: 'Email', value: 'email'},
        {text: 'Imię', value: 'firstName'},
        {text: 'Nazwisko', value: 'lastName'},
        {text: 'Status konta', value: 'emailConfirmed'},
        {text: 'Zablokuj', value: 'changeStatus', sortable: false},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchUsers()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchUserList', 'showNotification']),
    async fetchUsers() {
      this.$data.$_paginationMixin_fetchingItems = true
      try {
        const {search} = this
        const query = this.$_paginationMixin_createQuery({
          ...this.$data.$_paginationMixin_options,
          search,
        })
        this.$data.$_paginationMixin_totalCount = await this.fetchUserList({
          params: {
            ...query,
          },
        })
        this.$data.$_paginationMixin_fetchingItems = false
      } catch {
        this.$data.$_paginationMixin_fetchingItems = false
      }
    },
    showStatus(status) {
      return status ? 'Aktywne' : 'Nieaktywne'
    },
    openModal() {
      this.$refs.modal.open()
    },
    async changeStatus({id, emailConfirmed}) {
      const res = await this.$refs.confirmModal.open({
        title: `${emailConfirmed ? 'Blokowanie' : 'Aktywacja'} konta`,
        message: `Czy na pewno chcesz ${emailConfirmed ? 'zablokować' : 'aktywować'} konto?`,
      })
      if (res) {
        try {
          await UsersRepository.changeUserStatus(id)
          await this.fetchUsers()
          this.$swal({
            title: `Konto zostało ${emailConfirmed ? 'zablokowane' : 'aktywowane'}`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          })
        } catch (e) {
          this.showNotification({
            message: e.response,
            type: 'error',
          })
        }
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.users.userList,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
      }
    },
  },
}
</script>

<style scoped></style>
