<template>
  <v-dialog max-width="370" v-model="show">
    <v-form
      :valid="valid"
      @submit.prevent="addUser"
      @keyup.enter="addUser"
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">Zaproś nowego użytkownika</h6>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :rules="$rules.required"
            v-model="form.email"
            outlined
            dense
            label="Email"
            type="email"
          />
          <v-text-field
            :rules="$rules.required"
            v-model="form.firstName"
            outlined
            dense
            label="Imię"
            type="text"
          />
          <v-text-field
            :rules="$rules.required"
            v-model="form.lastName"
            outlined
            dense
            label="Nazwisko"
            type="text"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="pending">Zaproś</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'UsersModal',
  data() {
    return {
      valid: true,
      pending: false,
      alert: true,
      show: false,
      form: {
        email: '',
        firstName: '',
        lastName: '',
      },
    }
  },
  methods: {
    ...mapActions(['registerUser', 'showNotification']),
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.$refs.form.reset()
    },
    async addUser() {
      if (this.$refs.form.validate() && !this.pending) {
        try {
          this.pending = true
          await this.registerUser(this.form)
          this.close()
          this.pending = false
          this.$emit('userAdded')
        } catch {
          this.showNotification({
            message: 'NOTY_EMAIL_EXISTS',
            type: 'error',
          })
          this.pending = false
        }
      }
    },
  },
}
</script>

<style scoped></style>
